<template>
  <b-container class="mt-4">
    <b-card header-class="header">
      <template #header>
        <label class="mr-2 text-white">Ferdi Yarışma Gruplarım</label>
        <b-button
          :to="{
            name: 'CompetitionDetail',
            params: { id: $route.params.id },
          }"
          variant="primary"
          class="ml-4"
        >
          Yarışma Bilgileri
        </b-button>
      </template>

      <b-alert show variant="info"
        >Onaylanmamış gruplardan çıkmak için 'x' butununu
        kullanabilirsiniz</b-alert
      >
      <b-alert show variant="warning"
        >Onaylanmış bir gruptan çıkmak için lütfen federasyon ile iletişime
        geçin</b-alert
      >
      <PMSTable
        :tableItems="individualGroups"
        :nameWithSurname="true"
        hasAction="personel"
        :erasable="true"
        :tableFields="fields"
        deleteMessage="Grubu silmek istediğinize emin misiniz? Grupta olan diğer üyeler de gruptan ayrılacaktır"
        approveMessage="Gruptan ayrılmak istediğinize emin misiniz?"
        @multipleDeleteAction="deleteMultipleRequest"
        @multipleApproveAction="exitGroupRequest"
        :limit="10000"
        :selectable="false"
        :infinitePageItems="true"
        :actionModes="['Sil', 'Onayla']"
      ></PMSTable>
    </b-card>
  </b-container>
</template>
  
  <script>
import { mapGetters } from "vuex";
import PMSTable from "../../../components/tables/GeneralTable.vue";
export default {
  components: {
    PMSTable,
  },
  computed: {
    ...mapGetters({
      getSelections: "getManagementItems",
      getIndividualGroups: "getIndividualGroups",
    }),
    individualGroups: {
      get() {
        return this.getIndividualGroups.map((g) => {
          return {
            id: g.id,
            personels: [
              g.personel1,
              g.personel2,
              g.personel3,
              g.personel4,
              g.personelBackup,
              g.personelBackup2,
            ].filter((n) => n != null),
            personel1: g.personel1,
            personel2: g.personel2,
            personel3: g.personel3,
            personel4: g.personel4,
            personelBackup: g.personelBackup,
            personelBackup2: g.personelBackup2,
            boatClass: this.getSelections.find((x) => x.id == g.boatType)
              ?.title,
            distance: this.getSelections.find((x) => x.id == g.distance)?.title,
            category: this.getSelections.find((x) => x.id == g.category)?.title,
            uid: g.uid,
            events: g.events,
            approved: g.approved,
          };
        });
      },
      set(value) {
        this.$store.commit("setIndividualGroups", value);
      },
    },
  },
  data() {
    return {
      fields: [
        { key: "id", label: "Sıra No" },
        { key: "approved", label: "Onay Durumu" },
        { key: "uid", label: "Grup Kodu" },
        { key: "names", label: "Personeller" },
        { key: "category", label: "Kategori" },
        { key: "boatClass", label: "Tekne Sınıfı" },
        { key: "distance", label: "Mesafe" },
        { key: "events", label: "İşlemler" },
      ],
    };
  },
  created() {
    this.$store.dispatch("getIndividualGroupsByCompetitionIdAndUserId", {
      userId: this.$store.getters.getUser.id,
      competitionId: this.$route.params.id,
    });
    //this.$store.dispatch("initSingleCompetition", this.$route.params.id);
  },
  methods: {
    deleteMultipleRequest(ids) {
      this.$store.dispatch("deleteMultipleIndividualGroupsUser", {
        ids: ids,
        params: {
          userId: this.$store.getters.getUser.id,
          competitionId: this.$route.params.id,
        },
      });
    },
    getPosition(group) {
      console.log(group);
      if (group.personel1.id == this.$store.getters.getUser.id)
        return "personelId1";
      else if (group.personel2?.id == this.$store.getters.getUser.id)
        return "personelId2";
      else if (group.personel3?.id == this.$store.getters.getUser.id)
        return "personelId3";
      else if (group.personel4?.id == this.$store.getters.getUser.id)
        return "personelId4";
      else if (group.personelBackup?.id == this.$store.getters.getUser.id)
        return "personelIdBackup";
      else if (group.personelBackup2?.id == this.$store.getters.getUser.id)
        return "personelIdBackup2";
      else return null;
    },
    exitGroupRequest(ids) {
      console.log(ids);
      this.$store.dispatch("exitIndividualGroup", {
        id: ids[0],
        userId: this.$store.getters.getUser.id,
        competitionId: this.$route.params.id,
        position: this.getPosition(
          this.getIndividualGroups.find((x) => x.id == ids[0])
        ),
      });
    },
  },
};
</script>
  
  <style scoped>
.header {
  background: rgb(108, 117, 125);
  background: linear-gradient(
    180deg,
    rgb(108, 117, 125) 10%,
    rgba(53, 57, 61, 1) 85%
  );
}
</style>